<template>
  <b-sidebar
    id="add-avis-sidebar"
    :visible="isAddAvisSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-avis-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-primary text-white">
        <h5 v-if="!id" class="mb-0 text-white">
          Nouveau avis
        </h5>
         <h5 v-else class="mb-0 text-white">
          Modifier avis
        </h5>

        
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer ref="simpleRules">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent=""
          @reset.prevent=""
        >

            <b-form-group
              label="Avis"
              label-for="avis"
            >
              <validation-provider
                #default="{ errors }"
                name="avis"
                rules="required"
              >
                <b-form-input
                  id="avis"
                  v-model="avis"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="Avis"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

              <b-form-group
              label="Nom du client"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  id="nom"
                  v-model="nom"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nom du client"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

             <b-form-group
              label="E-mail"
              label-for="E-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="E-mail"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="E-mail"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

             <b-form-group
              label="Note"
              label-for="note"
            >
              <validation-provider
                #default="{ errors }"
                name="note"
                rules="required"
              >
                <b-form-input
                  id="note"
                  v-model="note"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false:null"
                  placeholder="La note"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


             <b-form-group
                label="Artisan"
                label-for="artisan"
            >
                <validation-provider
                    #default="{ errors }"
                    name="Artisan"
                    rules="required"
                >
                    <v-select
                        v-model="artisan_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="artisanOptions"
                        label="nom"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="artisan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <b-form-group
              label="Status"
              label-for="status"
            >
              <b-form-checkbox
                :checked="status"
                v-model="status"
                class="custom-control-success"
                name="status"
                switch
              />
            </b-form-group>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="add"
            >
              <span v-if="!id">Ajouter</span>
              <span v-else >Modifier</span>

            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions } from "vuex"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddAvisSidebarActive',
    event: 'update:is-add-avis-sidebar-active',
  },
  props: {
    isAddAvisSidebarActive: {
      type: Boolean,
      required: true,
    },
    avisData: {
      type: Array,
      required: true,
    },
    artisanOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      avis: null,
      nom: null,
      email: null,
      note: null,
      artisan_id: null,
      status: true,
      required,

    }
  },
  methods: {
    ...mapActions('avis', ['addItem','updateItem']),

    notification(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
          text: text,
          variant
        },
      })
    },


    init(close = false) {
      if(!close && this.avisData.id) {
        this.id = this.avisData.id
        this.avis = this.avisData.text
        this.nom = this.avisData.nom
        this.email = this.avisData.email
        this.note = this.avisData.note
        this.artisan_id = this.avisData.artisan.id
        this.status = this.avisData.status ? true : false

        return
      }

      this.id = null
      this.avis = null
      this.nom = null
      this.email = null
      this.note = null
      this.note = null
      this.artisan_id = null
      this.$refs.simpleRules.reset()
     
    },

    add() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(!this.id) {
            this.addItem({avis: this.avis, nom: this.nom, email: this.email,note: this.note,  status: this.status,artisan_id: this.artisan_id}).then(() => {
              this.$emit('update:is-add-avis-sidebar-active', false)
              this.notification('success', 'Ajout', 'Votre avis est bien ajouté.')
            })
            .catch(() => {
              this.notification('danger', 'Ajout', 'Votre avis n\'est pas ajouté.')
            })
          }else{
            this.updateItem({id: this.id, avis: this.avis, nom: this.nom, email: this.email,note: this.note,  status: this.status,artisan_id: this.artisan_id}).then(() => {
              this.$emit('update:is-add-avis-sidebar-active', false)
              this.notification('success', 'Modification', 'L\'avis est bien modifié.')
            })
            .catch(() => {
              this.notification('danger', 'Modification', 'Votre avis n\'est pas modifié.')
            })
          }
        }
      })
    },
  },

  created()  {
    
  },



  watch: {
    isAddAvisSidebarActive(val) {
      if(!val) {
        this.init(true)
        return
      }

      this.init()
    }
  }
 
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-service-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
