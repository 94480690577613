<template>
  <div>
    <avis-add-update
      :is-add-avis-sidebar-active.sync="isAddAvisSidebarActive"
      :avis-data="avisSidebarData"
      :artisan-options="artisans"
      @refetch-data="refetchData"
    />

    <div class="row">
      <div class="col-md-12 mt-1">
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="avisSearchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="addAvis"
                  >
                    <span class="text-nowrap">Ajouter nouveau</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

        </div>

          <b-table
            class="position-relative"
            responsive
            :fields="avisColumns"
            :items="avis"
            :current-page="currentPage"
            :per-page="perPage"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <template #cell(status)="data">
              <b-badge pill variant="success" v-if="data.item.status">Activé</b-badge>
              <b-badge pill variant="danger" v-else>Désactivé</b-badge>
            </template>

            <template #cell(actions)="data">
              <b-button variant="flat-info" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-info title="Modifier"  @click="editAvis(data.item)">
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
    
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
    
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalAvis"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
    
              </b-col>
    
            </b-row>
          </div>


        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
  import AvisAddUpdate from './AvisAddUpdate.vue'
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { mapGetters, mapActions } from "vuex"
  import Ripple from 'vue-ripple-directive'


  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,

      vSelect,

      AvisAddUpdate
    },

    data() {
      return {
        avisColumns : [
          { label: 'avis', key: 'text', sortable: true },
          { label: 'nom', key: 'nom', sortable: true },
          { label: 'email', key: 'email', sortable: true },
          { label: 'date', key: 'date', sortable: true },
          { label: 'note', key: 'note', sortable: true },
          { label: 'artisan', key: 'artisan.name', sortable: true },
          { label: 'status', key: 'status', sortable: true },
          { label: 'Actions', key: 'actions', thClass: 'w-auto text-center', tdClass: 'text-center w-auto' },
        ],
        isSortDirDesc : true,
        perPage : 10,
        currentPage : 1,
        totalAvis : 0,
        perPageOptions : [10, 25, 50, 100],
        isAddAvisSidebarActive: false,
        AvisSearchQuery: '',
        avisSidebarData: {id: null, avis: null, nom: null, email: null, note: null,artisan_id:null, status: 1},
        
      }
    },

    computed: {
      ...mapGetters('avis', {'avis' : 'GET_ITEMS'}),
      ...mapGetters('artisans', {'artisans' : 'GET_ITEMS'}),

    },

    methods: {
      ...mapActions('avis', {'fetchAvis' : 'fetchItems'}),
      ...mapActions('artisans', {'fetchArtisans' : 'fetchItems'}),

      addAvis() {
        this.avisSidebarData = {id: null, avis: null, nom: null, email: null, note: null,artisan_id:null, status: 1}
        this.isAddAvisSidebarActive = true
      },

      editAvis(data) {
        this.avisSidebarData = data
        this.isAddAvisSidebarActive = true
      }
    },

    created() {
      this.fetchAvis().then(() => {
        this.totalAvis = this.avis.length
      })
      this.fetchArtisans()

    },

    watch: {
      avisSearchQuery(val) {
        console.log('service search : ' + val);
      }
    },

    directives: {
      'b-tooltip': VBTooltip,
      Ripple
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

